import React, {
    ChangeEvent,
    ChangeEventHandler,
    FormEvent,
    FormEventHandler,
    MouseEvent,
    MouseEventHandler
} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import './App.css';
import {Col, Container, Row} from "react-bootstrap";

interface WelcomeProps {
  currentStep: number,
    currentClassSession?: ClassSession
}

interface PairsProps {
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  currentStep: number,
  pairs: string,
  timerCompleteHandler: (totalElapsedTime: number) => void
}

interface SummaryProps {
  currentStep: number,
  submissionResponse?: PairSubmissionResponse
}

interface PairCount {
    text: string,
    count: number
}
interface ClassSessionResults {
    pairCounts: PairCount[],
    studentCount: number
}

interface ResultsListProps {
    results?: ClassSessionResults
}

interface PairSubmissionResponse {
    pairsSaved: number
}

interface TeacherModeProps {
    currentStep: number,
    teacherMode: boolean,
    handleTeacherModeClick: MouseEventHandler<HTMLButtonElement>
}

interface TeacherAppProps {
    currentStep: number,
    teacherMode: boolean,
    currentClassSession?: ClassSession,
    createClassSession: FormEventHandler<HTMLFormElement>,
    handleFormInputChange: ChangeEventHandler<HTMLInputElement|HTMLSelectElement>,
    handleCloseSessionClick: MouseEventHandler<HTMLButtonElement>,
    classSessionResults?: ClassSessionResults
}

interface JoinSessionProps {
    joinCodeChangeHandler: ChangeEventHandler<HTMLInputElement>,
    joinClassSession: FormEventHandler<HTMLFormElement>,
    currentStep: number
}

interface ClassSession {
    id: number,
    joinCode: string,
    teacherName: string,
    teacherEmail: string,
    displayName: string
}

interface AppState {
    currentStep: number,
    pairs: string,
    teacherMode: boolean,
    studentCurrentSessionJoinCode: string,
    currentClassSession?: ClassSession,
    loading: boolean,
    isError: boolean,
    errorMessage: string,
    txtTeacherName: string,
    txtTeacherEmail: string
    txtClassName: string,
    dlClassGrade: string,
    classSessionResults?: ClassSessionResults,
    pairsSubmissionResponse?: PairSubmissionResponse
}

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

// noinspection LanguageDetectionInspection
class App extends React.Component <{}, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
        currentStep: 0,
        pairs: '',
        teacherMode: false,
        studentCurrentSessionJoinCode: '',
        loading: false,
        isError: false,
        errorMessage: '',
        txtTeacherName: '',
        txtTeacherEmail: '',
        txtClassName: '',
        dlClassGrade: ''
    }
  }

  handlePairsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = event.target as HTMLTextAreaElement;
    this.setState({
      pairs: value
    });
  }

    handleCreateSessionFormChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value} as unknown as AppState);
    }

  handleStudentJoinCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target as HTMLInputElement;
      this.setState({
          studentCurrentSessionJoinCode: value
      });
  }

  _next = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep >= 2? 3: currentStep + 1
    this.setState({
      currentStep: currentStep
    })
  }

  _prev = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1? 1: currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }

  enterTeacherMode = () => {
      this.setState({
          teacherMode: true
      });
  }

  startLoading = () => {
      this.setState({
          loading: true
      });
  }

    stopLoading = () => {
        this.setState({
            loading: false
        });
    }
    exitTeacherMode = () => {
        this.setState({
            teacherMode: false
        });
    }

  nextButton(){
    let currentStep = this.state.currentStep;
    if(currentStep === 1){
      return (
          <button
              className="btn btn-primary float-right"
              type="button" onClick={this._next}>
            Let's Go!
          </button>
      )
    }
    return null;
  }

  setError(message: string) {
      this.setState({
          isError: true,
          errorMessage: message
      });
  }

  clearError() {
      this.setState({
          isError: false,
          errorMessage: ''
      })
  }

  timerComplete = (totalElapsedTime: number) => {
      this.submitAnswers();
  }

  submitAnswers = () => {
      this.startLoading();
      fetch(apiBaseURL + 'student', {

          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          // We convert the React state to JSON and send it as the POST body
          body: JSON.stringify(this.state)
      }).then(function(response) {
          console.log(response)
          if(response.status === 200) {
              return response.json();
          }
          else {
              return Promise.reject('error submitting pairs answers: ' + response.status);
          }
      }).then(data => {
          console.log('data: ', data);
          this.setState({
              pairsSubmissionResponse: data
          })
          this.stopLoading();
      })
      .catch(error => {
          console.log('error submitting pairs: ', error);
          this.stopLoading();
          this.setError('error submitting pairs: ' + error.message);
      })
      .finally(() => this._next());
  }

  handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    this.submitAnswers();
  }

    handleCreateClassSessionSubmit = (event: FormEvent) => {
        event.preventDefault();
        this.startLoading();

        fetch(apiBaseURL + 'classsession?' + new URLSearchParams({
            teachername: this.state.txtTeacherName,
            teacheremail: this.state.txtTeacherEmail,
            className: this.state.txtClassName,
            classGrade: this.state.dlClassGrade,
        }))
            .then(function(response) {
                if(response.status === 200) {
                    return response.json();
                }
                else if(response.status === 404) {
                    return Promise.reject('Join code was not found');
                } else {
                    return Promise.reject('other error: ' + response.status);
                }
            })
            .then(data => {
                console.log('data: ', data);
                this.setState({
                    currentClassSession: data
                })
                // this._next();
                this.stopLoading();
            })
            .catch(error => {
                console.log('error creating class session: ', error);
                this.stopLoading();
                this.setError('error creating class session: ' + error.message);
            });
    }

    handleClickedTeacherMode = (event: MouseEvent) => {
        this.enterTeacherMode();
    }

    handleClickedTeacherCloseSession = (event: MouseEvent) => {
      this.startLoading();
        fetch(apiBaseURL + 'classsession?joinCode=' + this.state.currentClassSession?.joinCode, {

            method: 'DELETE',
            headers: {
                'X-bb-secret': 'super secret'
            }
        }).then(function(response) {
            console.log(response)
            return response.json();
        }).then(data => {
            console.log('data: ', data);
            this.setState({
                classSessionResults: data
            })
            // this._next();
            this.stopLoading();
        })
            .catch(error => {
                console.log('error closing class session: ', error);
                this.stopLoading();
                this.setError('error closing class session: ' + error.message);
            });;
    }

    handleStudentJoinCodeSubmit = (event: FormEvent) => {
        event.preventDefault();
        this.startLoading();
        this.clearError();
        const joinCode = this.state.studentCurrentSessionJoinCode;
        console.log("join code: " + joinCode);
        fetch(apiBaseURL + 'student?joinCode=' + joinCode)
            .then(function(response) {
                if(response.status === 200) {
                    return response.json();
                }
                else if(response.status === 404) {
                    return Promise.reject('Join code was not found');
                } else {
                    return Promise.reject('other error: ' + response.status);
                }
            })
            .then(data => {
                console.log('data: ', data);
                this.setState({
                    currentClassSession: data
                })
                this._next();
                this.stopLoading();
            })
            .catch(error => {
                console.log('error: ', error);
                this.stopLoading();
                this.setError('error: ' + error.message);
            });
    }

  render() {
    return (
        <Container>
            {(this.state.currentStep !== 0 || this.state.teacherMode) && <AppHeader></AppHeader>}
            {!this.state.teacherMode && (
                <>
                    <JoinSession joinCodeChangeHandler={this.handleStudentJoinCodeChange} joinClassSession={this.handleStudentJoinCodeSubmit} currentStep={this.state.currentStep}></JoinSession>
                    <form onSubmit={this.handleSubmit}>
                        <Welcome currentStep={this.state.currentStep} currentClassSession={this.state.currentClassSession}></Welcome>
                        <ThingsInPairs handleChange={this.handlePairsChange} currentStep={this.state.currentStep}
                                       pairs={this.state.pairs}
                                        timerCompleteHandler={this.timerComplete}
                        ></ThingsInPairs>
                        <Summary currentStep={this.state.currentStep} submissionResponse={this.state.pairsSubmissionResponse}></Summary>
                        {this.nextButton()}
                    </form>
                </>
            )}
            {this.state.isError && <Alert variant="danger">
                {this.state.errorMessage}
            </Alert>}

            {this.state.loading && (<Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>)}
            {this.state.currentStep === 0 && !this.state.teacherMode && (
                <Row>
                    <TeacherMode currentStep={this.state.currentStep} teacherMode={this.state.teacherMode}
                                 handleTeacherModeClick={this.handleClickedTeacherMode}
                    ></TeacherMode>
                </Row>
            )}
            <TeacherApp
                currentStep={this.state.currentStep}
                teacherMode={this.state.teacherMode}
                currentClassSession={this.state.currentClassSession}
                handleFormInputChange={this.handleCreateSessionFormChange}
                createClassSession={this.handleCreateClassSessionSubmit}
                handleCloseSessionClick={this.handleClickedTeacherCloseSession}
                classSessionResults={this.state.classSessionResults}
            ></TeacherApp>
        </Container>
    )
  }
}

export default App;

function Welcome(props: WelcomeProps) {
  if(props.currentStep !== 1) {
    return null;
  }

  return (
      <div className="">
          <Row>
              <Col>
                  <p className="h2">Welcome to {props.currentClassSession?.displayName}'s brainstorm session!</p>
                  <p className="h4">Are you ready for a Brainstorming Challenge?</p>
                  <p className="font-weight-bold">How many things can you think of that come in pairs (things that come
                      in
                      two)?</p>
                  <p>You will have 3 minutes to think of as many things as you can and type them in the response boxes
                      on
                      the next page. </p>
                  List as many things as you can that come in pairs.
                  <RulesList></RulesList>

                  <p className="font-weight-bold">When you are ready to begin, click the blue button below. The timer
                      will
                      start as soon as you click it!</p>
              </Col>
              <Col sm="12" md="6">
                  <img className="d-block mb-4" src="/images/students_thinking.png" alt="" width="400" />
                  <div className="mb-3">
                      <p className="h4">Next Screen:</p>
                      <img className="shadow" src="/images/pairs_screenshot.png" />
                  </div>
              </Col>
          </Row>


          <Row>
              <Col>

              </Col>
              <Col>

              </Col>
          </Row>
      </div>
  );
}

function RulesList() {
    return (
        <ul>
            <li>Enter one response per line</li>
            <li>Click the enter or return button on your keyboard to enter the next response.</li>
        </ul>
    );
}

const renderTime = (remainingTime: number) => {
    if (remainingTime === 0) {
        return <div className="timer">Too late...</div>;
    }
    const minutes = Math.floor(remainingTime / 60);
    const seconds = (remainingTime % 60).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });



    return (
        <div className="timer">
            <div className="text">Remaining</div>
            <div className="value">{minutes}:{seconds}</div>
            <div className="text">seconds</div>
        </div>
    );
};

function ThingsInPairs(props: PairsProps) {
  if (props.currentStep !== 2) {
    return null;
  }
  return (
      <>
          <p className="font-weight-bold">List as many things as you can that come in pairs.</p>
          <RulesList></RulesList>
          <Row>
              <Col>
                  <div className="form-group">
                      <label htmlFor="pairs">List things that come in pairs (one answer per line):</label>
                      <textarea
                          className="form-control"
                          id="pairs"
                          name="pairs"
                          placeholder="Things that come in pairs"
                          value={props.pairs}
                          onChange={props.handleChange}
                          rows={15}
                      />
                      <button className="btn btn-success btn-block">Submit!</button>
                  </div>
              </Col>
              <Col className="my-auto text-center">
                  <CountdownCircleTimer
                      isPlaying={true}
                      duration={180}
                      colors={[
                          ['#004777', 0.33],
                          ['#F7B801', 0.33],
                          ['#A30000', 0.33],
                      ]}
                      onComplete={props.timerCompleteHandler}
                  >
                      {({remainingTime}) => renderTime(remainingTime as number)}
                  </CountdownCircleTimer>
              </Col>
          </Row>
      </>
  );
}

function Summary(props: SummaryProps) {
  if (props.currentStep !== 3) {
    return null;
  }
  return (
      <>
          <img className="mb-4" src="/images/great_job.jpg" alt="" width="350" />
        <h1>Great Job!</h1>
          <p className="lead">You submitted <strong>{props.submissionResponse?.pairsSaved}</strong> things that come in pairs.</p>
          <p>Stay on this page, and your teacher will give you instructions on what to do next.</p>
      </>
  );
}

// TODO: teacher session recovery
// TODO: home/back exit button from first teacher mode screen?
// TODO: show better error message for student join session not found
// TODO: preview the display name / welcome message on session creation page
// TODO: remove prod DB credentials from code and change password
// TODO: persist state to localstorage to better handle browser refreshes
// TODO: Show number of students that have submitted on join code display screen

// TODO: Google Analytics?
// TODO: add good job image to student summary page, and their pair count
// TODO: show a star for each answer student submitted on summary page (5 pairs gets 5 stars)
// TODO: summary: style the number of pairs more

function JoinSession(props: JoinSessionProps) {
    if(props.currentStep !== 0) {
        return null;
    }

    return (
      <>
          <div className="text-center">
              <img className="d-block mx-auto mb-4" src="/images/k12logo-blue-stacked.png" alt="" width="350" />
              <h1 className="display-4 font-weight-bold">Brain Builders</h1>
              <div className="col-lg-8 mx-auto">
                  <p className="lead mb-4">Welcome to Brain Builders, a fun way to boost creativity for inventing. In this activity, students will come up with as many answers as they can to a short question. Teachers then use the student responses to facilitate a class discussion. Let's get those ideas flowing!</p>
                  <p className="h2">Students</p>
                  <form onSubmit={props.joinClassSession}>
                      <div className="form-group">
                          <Row>
                              <Col><label htmlFor="joincode">Enter the join code from your teacher:</label></Col>
                          </Row>
                          <Row>
                              <Col className="p-0"></Col>
                              <Col xs={3} className="p-0"><input type="text" className="form-control Code-input" id="joincode" name="joincode" onChange={props.joinCodeChangeHandler} placeholder="Join Code" /></Col>
                              <Col xs={3} className="p-0"><button type="submit" className="btn btn-success btn-lg">Join Session</button></Col>
                              <Col className="p-0"></Col>
                          </Row>
                      </div>
                  </form>
              </div>
          </div>
      </>
    );
}

function ResultsList(props: ResultsListProps) {
    if(!props.results) {
        return null;
    }

    const pairs = props.results.pairCounts;
    const tableRows = pairs.map((pair) =>
        <tr><td>{pair.text}</td><td>{pair.count}</td></tr>
    );
    return (
        <table className="table table-hover">
            <thead>
            <tr>
                <th scope="col">Answer</th>
                <th scope="col">Number of Students</th>
            </tr>
            </thead>
            <tbody>
            {tableRows}
            </tbody>
        </table>
    )
}

function TeacherApp(props: TeacherAppProps) {
    if(!props.teacherMode) {
        return null;
    }

    return (
        <>

            {props.currentClassSession && !props.classSessionResults && (
                <>
                    <h2>Welcome Teachers!</h2>
                    <div className="col-3">
                        <p className="lead">Your class join code is</p>
                        <div className="alert alert-success d-inline-block" role="alert">
                            <div className="font-weight-bold Code-display text-center display-1">{props.currentClassSession.joinCode}</div>
                        </div>
                        <p className="small">When your students have completed the activity, click the button below to close the session and show the class results.</p>
                        <button className="btn btn-success" onClick={props.handleCloseSessionClick}>End session and show data</button>
                    </div>
                </>
            )}
            {props.classSessionResults && (
                <>
                    <h2>Class Results for {props.currentClassSession?.displayName}</h2>
                    <div className="row">
                        <div className="col">
                            <p className="lead">The table below shows all of the answers from your class session, along
                                with the number of students who gave that same answer.</p>
                            <div className="alert alert-secondary" role="alert">
                                Print or save this page as a PDF if you would like to save the results for later use.
                            </div>
                            <ResultsList results={props.classSessionResults}></ResultsList>
                        </div>
                        <div className="col">
                            <div className="alert alert-info" role="alert">
                                <h4 className="alert-heading">Congratulations on finishing the activity!</h4>
                                <p>Consider these tips for discussion, and try running another session to compare the results:</p>
                                <hr />
                                <ol>
                                    <li>Come up with as many ideas as you can and make sure to write them down!</li>
                                    <li>Don’t judge any idea! Every single idea you come up with could be a good idea. The
                                        weirder the answer, the better!
                                    </li>
                                    <li>When you think you are out of ideas, don’t give up! Think about a different way to
                                        look at the question.
                                        <ol type="a">
                                            <li>For example, if the question is, “What is something you can make with 3
                                                circles?”, some people may think of creating a snowman, but if you were to
                                                shrink two circles, you could create Mickey Mouse!
                                            </li>
                                            <li>Imagine different places! There are things in your classroom that can come
                                                in pairs, but there are also things at home, in your room, in the kitchen,
                                                in the jungle, and so many more places.
                                            </li>
                                            <li>Look at your previous answers! You can come up with ideas that are similar
                                                to your answers, or you could connect two answers to come up with a
                                                different answer! For example, if you wrote down “eyes”, you could also
                                                think of other body parts that come in pairs!
                                            </li>
                                        </ol>
                                    </li>
                                    <li>Once you're done brainstorming by yourself, discuss your answers with your peers!
                                        Your classmates think differently than you do and will come up with some cool
                                        answers that you wouldn’t have thought of!
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {!props.currentClassSession && (
                <>
                    <p className="lead">
                        Complete the form below to create a join code that your students can use to join your class session.
                    </p>
                    <form onSubmit={props.createClassSession}>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="txtTeacherName">Teacher Name</label>
                                <input type="text" className="form-control" id="txtTeacherName" name="txtTeacherName" onChange={props.handleFormInputChange} />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="txtTeacherEmail">Teacher Email</label>
                                <input type="email" className="form-control" id="txtTeacherEmail" name="txtTeacherEmail" onChange={props.handleFormInputChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-4">
                                <label htmlFor="txtClassName">Class Name</label>
                                <input type="text" className="form-control" id="txtClassName" name="txtClassName" onChange={props.handleFormInputChange} />
                                <small id="classNameHelp" className="form-text text-muted">This will be shown to your students.</small>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="dlClassGrade">Class Grade Level</label>
                                <select onChange={props.handleFormInputChange} className="form-control" id="dlClassGrade" name="dlClassGrade">
                                    <option value="">Select grade...</option>
                                    <option value="pre-k">Pre-K</option>
                                    <option value="k">K</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>

                        <button className="btn btn-success">Create Session</button>
                    </form>
                </>
            )}
        </>
    )
}

function TeacherMode(props: TeacherModeProps) {
    if(props.currentStep !== 0) {
        return null;
    }

    return (
      <div className="col-lg-8 mt-5 mx-auto text-center">
          <p className="h4">Teachers</p>
          <p className="mb-3">Quickly start a brain builder activity by clicking the button below.</p>
          <button className="btn btn-outline-primary ml-2" onClick={props.handleTeacherModeClick}>Create New Session</button>
      </div>
    );
}

function AppHeader() {
    return (
        <>
            <img className="mb-4 mr-3" src="/images/k12logo-blue-long.png" alt="" width="350" />
            <span className="display-4">Brain Builders</span>
        </>
    );
}
